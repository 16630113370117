import * as React from 'react';
import { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import moment from "moment";

import config from "../config";
import { Card } from '@mui/material';

function removeObjectWithId(arr, id) {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1);
  }

  return arr;
}

export default function NewsCard(n) {
    const [expanded, setExpanded] = React.useState(false);
    const [favorite, setFavorite] = React.useState(n.favorite);


    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
      })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    }));
    
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };


    const handleShare = async () => {
      try {
        await navigator
          .share(n.site_link)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    };


    const handlePersist = () => {
      let d=localStorage.getItem('favorites');
      var od=new Array();
      (d)&&(od=(JSON.parse(d)));
      if(favorite){
        setFavorite(false);
        removeObjectWithId(od, n.id);
      }
      else{
        setFavorite(true);
        var newFavorite={...n, favorite: true};
        od.unshift(newFavorite);
      }
      localStorage.setItem('favorites', JSON.stringify(od));
      
    };

    return (
        <Card>
          <CardHeader sx={{ display: 'flex', flexDirection: 'row', alignContent: 'space-between' }}
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe"
                src={"https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://"+n.domain+"&size=32"}
                >
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={n.domain}
            subheader={moment.utc(n.postdate).fromNow()}
          />
          <CardMedia
            component="img"
            sx={{}}
            image={config.server_url+n.local_image}
            alt="random"
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="h2">
              {n.title}
            </Typography>
            <Typography>
              {n.description}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="add to favorites"
              onClick={(e) => 
                handlePersist('favorites')
              }
              color={(favorite) ? ('error') : ('default')}
            >
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share"
              onClick={(e) => 
                handleShare()
              }
              color='default'
            >
              <ShareIcon />
            </IconButton>
            {n.article_body? (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
            ):""}
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>{n.article_body}</Typography>
            </CardContent>
          </Collapse>
        </Card>
    );
}

