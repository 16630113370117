import { useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import NewsCard from "./NewsCard";
import config from "../config";
import rlog from "../lib/rlog";
const fetch = require('sync-fetch');

export default function TopNews() {

    const [news, setNews] = useState([]);
    const [pageCount, setPageCount]=useState(0);
    
    function refreshNews(pageCount) {
      var page = (pageCount!=0) ? ("_"+pageCount):"";

      rlog("Refresh news " + page);
  
      var url=config.server_url + "/topnews.json" + page + "?p=" + Date.now();
      var nn=fetch(url).json();
  
      //aggorno i vavoriti
      let d=localStorage.getItem('favorites');
      var fav=new Array();
      (d)&&(fav=(JSON.parse(d)));
  
      for(var i=0; i< nn.length;i++){
        nn[i].favorite=false;
        if(fav.find(el=>el.id==nn[i].id)){
          nn[i].favorite=true;
        }
      }
      (pageCount==0)?setNews(nn):setNews(news.concat(nn));
    };
  
    const handleMoreImage = () => {
      setPageCount(pageCount+1);
      refreshNews(pageCount+1);
    };

    useEffect(() => {
        refreshNews(0);
    }, []);

    return(

    <main>
    <Container sx={{ py: 1 }} maxWidth="lg">
      {/* End hero unit */}
      <Grid container spacing={4}>
        {news.map((n) => (
          <Grid item key={n.id} xs={12} sm={6} md={4}>
            <NewsCard key={"nk"+n.id}  {...n}></NewsCard>
          </Grid>
        ))}
      </Grid>
      {pageCount<10 && (
      <Button
        className="mt-4"
        onClick={handleMoreImage}
        >
        Load more
      </Button>
      )}
    </Container>
  </main>
    )
}