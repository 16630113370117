import { useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import NewsCard from "./NewsCard";
import config from "../config";
import rlog from "../lib/rlog";
const fetch = require('sync-fetch');

export default function Favorites() {

    const [news, setNews] = useState([]);

    useEffect(() => {
      rlog("Refresh favorites");
      let fav=localStorage.getItem('favorites');
      (fav)?setNews(JSON.parse(fav)):setNews([]);
      }, []);

    return(

    <main>
    <Container sx={{ py: 1 }} maxWidth="lg">
      <Grid container spacing={4}>
        {news.map((n) => (
          <Grid item key={n.id} xs={12} sm={6} md={4}>
            <NewsCard key={"nk"+n.id}  {...n}></NewsCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  </main>
    )
}