import * as React from 'react';
import { PwaUpdater } from 'pwa-updater';
import { useState, useEffect, useRef } from "react";
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';


import NewsIcon from '@mui/icons-material/Dns';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import MyAppBar from './components/MyAppBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import TopNews from './components/TopNews';
import Favorites from './components/Favorites';
import Search from './components/Search';
import { createTheme, ThemeProvider } from '@mui/material/styles';




const theme = createTheme();

const actions = {
  0: {"name": "Top News"},
  1: {"name": "Favorites"},
  2: {"name": "Search"},
}


function App() {
  const [value, setValue] = useState(0);
  const ref = useRef(null);


  return (
    <ThemeProvider theme={theme}>
      <PwaUpdater notify={true} />
      <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        <MyAppBar title={actions[value].name}/>

        { value==0 && (
          <TopNews />
        )}

        { value==1 && (
          <Favorites />
        )}

        { value==2 && (
          <Search />
        )}

        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              console.log("button: " + newValue);
            }}
          >
            <BottomNavigationAction label={actions[0].name} icon={<NewsIcon />} />
            <BottomNavigationAction label={actions[1].name} icon={<FavoriteIcon />} />
            <BottomNavigationAction label={actions[2].name} icon={<SearchIcon />} />
          </BottomNavigation>
          <Typography paragraph></Typography>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}


export default App;
