import { useState, useEffect, useRef } from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import NewsCard from "./NewsCard";
import SearchBar from '@mkyy/mui-search-bar';
import config from "../config";
import rlog from "../lib/rlog";

const fetch = require('sync-fetch');

export default function Search(props) {

    const [news, setNews] = useState([]);
    const [textFieldValue, setTextFieldValue] = useState('');

    const handleCancelResearch = () => {
      console.log("cancel");
      setNews([]);
    }

    const handleSearch = () => {
      console.log(textFieldValue);
      rlog("Refresh favorites " + textFieldValue);
      if(textFieldValue?.length>2){
        var url="https://xpapi.xpilon.com/prod/SearchNews?searchString=" + textFieldValue;
        var nn=JSON.parse(fetch(url).json().news);
        setNews(nn);
      }
    }

    return(
      <main>
      <Container sx={{ py: 1 }} maxWidth="lg" style={{
              paddingTop: '70px',
            }}>
        <SearchBar 
          value={textFieldValue}
          onChange={newValue => setTextFieldValue(newValue)}
          onSearch={handleSearch}
          onCancelResearch={handleCancelResearch}
        />
        <Grid container spacing={4}>
          {news.map((n) => (
            <Grid item key={n.id} xs={12} sm={6} md={4}>
              <NewsCard key={"nk"+n.id}  {...n}></NewsCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  )
}